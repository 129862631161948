<template>
  <div class="listPage">
    <div class="line"></div>
    <div class="listBox" v-if="list.length > 0">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <List
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <template v-for="(item, index) in list">
            <div :key="index" class="meet">
              <div class="meetName" @click="goDetailPage(item)">
                <div class="name">
                  <img src="../../assets/meetList.png" width="20px" />
                  <span>{{ item.meetTitle }}</span>
                </div>
                <Icon
                  class="arrow"
                  name="arrow"
                  size="16"
                  color="#666"
                  v-if="item.orderStatus == 0 || item.orderStatus == 1"
                />
              </div>
              <div class="meetName meetTime"  @click="goDetailPage(item)">
                <div class="timeBox">
                  <div>
                    <span>开始时间：</span>
                    <span class="time">{{ item.meetTimeStart }}</span>
                  </div>
                  <div>
                    <span>结束时间：</span>
                    <span class="time">{{ item.meetTimeEnd }}</span>
                  </div>
                </div>
                <span :class="item.orderStatus == 1 ? 'blueColor' : ''">{{
                  ["待报名", "报名成功", "已取消", "退款中", "已退款"][
                    item.orderStatus
                  ]
                }}</span>
              </div>
            </div>
          </template>
        </List>
      </van-pull-refresh>
    </div>
    <div v-else class="noMeet">
      <Icon name="notes-o" color="#999" size="100" />
      <span>暂时没有任何培训</span>
    </div>
  </div>
</template>

<script>
import { Tab, Tabs, Icon, List } from "vant";
import { getMeetOrders } from "@/api/my.js";

export default {
  name: "myMeetList",
  components: {
    Tab,
    Tabs,
    Icon,
    List,
  },
  data() {
    return {
      activeIdx: 0,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      pageIndex: 1,
      pageSize: 10,
      total: 0,
    };
  },
  created() {
    this.getList(1);
  },
  // mounted(){
  //    let mchData={action:"jumpOut",jumpOutUrl:config.baseUrl.onlineUrl+`/myMeetList`};
  //     let postData=JSON.stringify(mchData)
  //     parent.postMessage(postData, 'https://payapp.weixin.qq.com')
  // },
  methods: {
    getList(page) {
      let clientId = localStorage.getItem("clientId");
      if (this.list.length < this.total || page == 1) {
        getMeetOrders({
          clientId,
          pageIndex: page,
          pageSize: this.pageSize,
        }).then((res) => {
          this.loading = false;
          this.refreshing = false;
          if (res.status == "S") {
            let { list, total } = res.data;
            let newList = page == 1 ? list : [...this.list, ...list];
            this.list = [...newList];
            this.pageIndex = page;
            this.total = total;
          }
        });
      } else {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
    },
    goDetailPage(item) {
      if (item.orderStatus == 1) {
        //已报名
        this.$router.push("/myMeetDetail?orderId=" + item.id);
      }
      if (item.orderStatus == 0) {
        //待报名
        this.$router.push(`/meetSignUp?meetId=${item.meetId}`);
      }
    },
    onLoad() {
      this.getList(this.pageIndex + 1);
    },
    onRefresh() {
      this.loading = true;
      this.total=0
      this.finished = false;
      this.getList(1);
    },
  },
};
</script>

<style lang="less" scoped>
@import "./../meetList/index.less";
.listPage {
  padding-top: 0rem;
  .line {
    height: 0.12rem;
    background: #f5f5f5;
  }
  .listBox {
    max-height: calc(~"100vh - 0.12rem");
    overflow-y: auto;
  }
  .noMeet {
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      margin-top: 0.3rem;
      color: #999;
      font-size: 22px;
      font-weight: bold;
    }
  }
}
</style>
